<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_home_05.png"
            alt=""
          />
        </div>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              title="Incertezas e agravos na vida ‘provisória’ em Mariana"
              titleColor="#426E51"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  De forma repentina, o rompimento da barragem de Fundão
                  provocou a ruptura no cotidiano das famílias em seus
                  territórios. No contexto do desastre, centenas de moradores
                  das comunidades de Bento Rodrigues e Paracatu de Baixo foram
                  realocados na sede urbana de Marina. Ali, as famílias foram
                  submetidas a uma rotina excepcional centrada na espera pelo
                  reassentamento e outras medidas de reparação. A experiência do
                  deslocamento compulsório configurou para as vítimas uma ‘<a
                    class="pink--text"
                    style="text-decoration: none;"
                    href="https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2019/06/MONOGRAFIA-Maryellen5.pdf"
                    target="_blank"
                    >vida provisória</a
                  >’ em um espaço completamente distinto das suas comunidades de
                  origem.
                </p>
                <p>
                  A distância entre parentes e vizinhos é causa significativa de
                  sofrimento, pois a dissolução dos núcleos próximos representou
                  também a ruptura das redes de reciprocidade e solidariedade,
                  ocasionando o isolamento dos grupos familiares. Os encontros
                  entre vizinhos foram dificultados pela dispersão e pelo
                  distanciamento entre as casas alugadas para abrigar os
                  atingidos e atingidas. Desde então, são frequentes as
                  observações de que as pessoas ficam dias ou até meses sem se
                  verem, conforme destacou uma moradora de Paracatu de Baixo em
                  2017<sup>1</sup>:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img
            width="80%"
            src="../../assets/conteudos_casas_vida_prov_01.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Reunião com os atingidos pela Barragem de Fundão. Foto: Jornal a
            Sirene, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="5"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0 white--text">
            “É, os amigos, os vizinhos... ficaram todos longe, né? Mas... vamos
            assim até quando Deus quiser. [...] Quando Deus quiser a gente
            volta... só que a gente não sabe se a gente volta pra aqui [para
            Paracatu de Baixo], pra onde a gente vai ainda, né?!”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  É possível apreender nessa fala a incerteza quanto ao futuro,
                  com o sentimento da perda de controle sobre o próprio destino
                  e sobre a composição dos horizontes possíveis.
                </p>
                <p>
                  Nesse sentido, com a realocação involuntária e a privação de
                  suas tradicionais redes de sociabilidade, o curso da vida foi
                  reconfigurado em uma dinâmica recorrente de reuniões e
                  mobilizações necessárias para a reivindicação dos direitos
                  violados. Tal dinâmica integra um laborioso processo de
                  aprendizado político, mediante o enfrentamento de burocracias
                  e da linguagem técnica e jurídica. Nesse processo, os espaços
                  de lutas (como reuniões, assembleias, audiências, oficinas)
                  também se transformaram em momentos de encontro, como nos
                  lembra outra moradora de Paracatu de Baixo:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row class="d-flex align-center justify-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image2"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            “Lá em Mariana mesmo, a gente quase não vê os vizinhos, porque todo
            mundo mora longe, né? É muito difícil... A gente os vê só dia que
            tem a missa. Porque aí vai todo mundo. Outra hora, quando a gente
            vai numa reunião, aí a gente se vê. (Atingida de Paracatu de Baixo,
            março de 2017)”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  As diversas rupturas cotidianas transformaram a “vivência do
                  desastre” em múltiplas experiências de perdas profundas, de
                  desorientação e de erosão da autonomia. Trata-se do
                  comprometimento da autodeterminação para planejar a própria
                  vida, já que os atingidos e atingidas se veem dependentes dos
                  ritmos e cronogramas das instituições envolvidas no processo
                  de reparação dos danos, como revela a avaliação de uma
                  liderança:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="5"
          class="d-flex justify-center align-center border__image3"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            “A situação dos atingidos, com todos os atingidos, é como se tivesse
            dado uma pausa na nossa vida. E a gente não consegue mais continuar,
            não consegue mais dar o play. (Audiência Pública, 20 de junho de
            2017)”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  O deslocamento para o centro urbano também provocou a
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_cultura_alimentar' }"
                    >transformação da alimentação</router-link
                  >, pois, em contraste aos territórios rurais que permitiam o
                  cultivo direto dos alimentos e o acesso a partir das relações
                  de reciprocidade, na cidade, imperam a troca mercantil e as
                  soluções individualizadas que podem comprometer a gestão
                  familiar dos recursos, segundo ressalta uma moradora de
                  Paracatu de Baixo.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row class="d-flex align-center justify-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image4"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            A gente estava aqui [em Paracatu] tranquilo, menina. A gente saiu
            corrido pra lá [Mariana] e a gente nem adaptou direito. Igual estou
            te falando: eu vivia com o salário, que é a pensão do meu marido e
            eu fazia algum bico aqui e ali. Eu me virava muito bem e lá
            [Mariana] não dá. Se for pra eu ficar com salário lá, não dá. Igual
            estou te falando: se eu quiser comer uma folha de couve, tem que
            comprar, se não tiver o dinheiro você não come. Tudo é comprado, não
            dá. E mesmo a Samarco dando o salário [auxílio emergencial], se a
            gente não souber controlar, não dá, não (Atingida de Paracatu de
            Baixo, março de 2017).
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  As despesas com a alimentação exemplificam a reconfiguração
                  das necessidades das famílias. Outra situação relatada está
                  relacionada aos gastos com o transporte escolar dos filhos. Em
                  Paracatu de Baixo, todos se conheciam, e a escola de ensino
                  fundamental estava localizada dentro do território, a poucos
                  metros de distância da maior parte das residências.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img
            width="80%"
            src="../../assets/conteudos_casas_vida_prov_02.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Horta cultivada por atingidos pela Barragem de Fundão. Foto: Tatiana
            Torres.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="5"
          class="d-flex justify-center align-center border__image5"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text text-center">
            “Em Paracatu, tudo era mais fácil, tudo era pertinho”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Essa realidade trazia tranquilidade e conforto para os pais
                  quanto à segurança dos filhos durante o percurso até a escola.
                  Porém, na sede urbana de Mariana, não são infrequentes as
                  despesas com o transporte escolar.
                </p>
                <p>
                  É preciso ainda acentuar que a antiga vida na “roça” é sempre
                  qualificada como uma experiência de segurança e “liberdade”
                  mesmo por atingidos e atingidas de diferentes idades. Nessa
                  medida, os quintais representam tanto a salvaguarda para o
                  orçamento doméstico e para a soberania alimentar, quanto a
                  possibilidade de desfrutar de uma condição distinta de vida.
                  Ter o “lá fora” é considerado um atributo da experiência no
                  antigo território:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row class="d-flex align-center justify-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image6"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            “Andar descalço na grama, cavucar a terra com as mãos, encontrar uma
            goiaba madura fora de época escondida no meio das folhas, seguir uma
            galinha e encontrar sua ninhada com vários pintinhos (Jornal A
            Sirene, nov. 2016<sup>2</sup>)”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img
            width="80%"
            src="../../assets/conteudos_casas_vida_prov_03.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Atingida pela Barragem de Fundão em sua moradia. Foto: Júlia
            Militão, 2020.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Não ter mais “o lá fora” representa, dessa forma, “ficar preso
                  dentro de casa”, afinal, o trabalho com as criações e
                  plantações do quintal foram drasticamente comprometidos.
                  Ademais, há uma contínua insegurança quanto aos riscos da
                  violência urbana, sobretudo, para a juventude que já não pode
                  desfrutar das mesmas práticas de encontro e lazer.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="5"
          class="d-flex justify-center align-center border__image7"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text pl-12 pr-0">
            “Morava em Paracatu de Baixo desde que nasci. Era tão gostoso o
            lazer lá. Chegava umas cinco horas da tarde, reunia um tanto de
            gente para jogar bola e as mães, Do Carmo, Sra. I. e a minha mãe,
            ficavam gritando a gente. Nos sábados e domingos, a nossa turminha
            sempre fazia um churrasquinho no Carlim, no Jairinho ou no Banana.
            Tínhamos nossa liberdade. A rua era nossa e ficávamos até tarde
            nela. Esquentávamos com o fogo na época de frio. Pegávamos bambu.
            Chegava época de calor e nós íamos para a cachoeira. Hoje, se
            sentimos calor, bebemos água, porque não tem mais cachoeira.
            Brincávamos de pique-esconde e polícia-ladrão à noite. Mexíamos com
            Duquinha e Nicanor. Era todo mundo conhecido. Éramos unidos. Hoje
            não. O meu povo de Paracatu está distante. Não tem lazer para os
            adultos e nem diversão para as crianças. As mães, antes, sabiam onde
            seus filhos estavam: no campo ou na rua. Em Mariana (sede), é
            complicado, nós temos que deixar os nossos filhos dentro de casa
            (Jornal A Sirene, abril 2018<sup>3</sup>)”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Estar fora do seu lugar sem ter a previsão de retorno ou
                  recomposição intensifica o processo de adoecimento dos
                  atingidos, além de representar sofrimento, em especial, para
                  os idosos, que vivem sem a perspectiva de conclusão dos
                  reassentamentos. As condições físicas da cidade de Mariana com
                  diversos locais com declividade acentuada dificultam a
                  mobilidade dos idosos que vêem seu cotidiano marcado pelo
                  isolamento e pelo ócio decorrente da perda da lida na roça e
                  de outras atividades exercidas nos territórios. A suspensão da
                  vida (tal como era vivida) por tempo indeterminado causa
                  angústia e é motivo para o aumento de casos de depressão:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row class="d-flex align-center justify-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image8"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            “É... eles [os idosos] são apaixonados com isso aqui [roça]. Sabe
            por quê? São nascidos e criados aí [Paracatu de Baixo], eles não
            conhecem outra vida, só conhecem isso aqui. Você vê que eles ficam
            tudo perdido ali em Mariana (Atingido de Paracatu de Baixo, março de
            2017)”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Além disso, o “viver na casa dos outros”, ou seja, por meio do
                  aluguel, é motivo de desassossego. A falta de autonomia se
                  concretiza, por exemplo, quando o atingido não pode realizar
                  modificações ou intervenções na residência, a exemplo de uma
                  simples instalação de quadros na parede. Outra situação
                  relatada é quanto à qualidade das habitações disponíveis em
                  Mariana, há diversas moradias com problemas de infiltração,
                  sem condições adequadas de ventilação ou mesmo de segurança,
                  com residências alugadas em locais com risco geológico ou de
                  inundações. Segundo o relatório da empresa Ramboll4 (2021),
                  46% das moradias temporárias em Mariana apresentam condições
                  de inadequação.
                </p>
                <p>
                  A falta de autonomia colocada pela moradia por residir em
                  casas alugadas é mais um fator de intranquilidade, o que acaba
                  intensificando a ansiedade de ter de volta a sua casa, a vida
                  de trabalho e o resgate do que a lama solapou.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="5"
          class="d-flex justify-center align-center border__image9"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text mb-4">
            “Eu acho uma falta de respeito com as pessoas lutarem uma vida
            inteira para ter a sua casa e morrer debaixo de telhado dos outros,
            debaixo de casa dos outros [...] não pode, uai. Se a casa é dos
            outros não pode. Como que você aluga a casa e fica lá colocando um
            monte de coisas?
            <strong
              >Como você planta lá umas latas de flores, como você planta alguma
              coisa lá? Na casa dos outros não pode, uai</strong
            >. Tem que ter cuidado com a casa dos outros. Você não pode ter o
            que você tem aqui [em Paracatu de Baixo], lá. Para eles, às vezes
            não significa nada, mas para quem gosta, para quem é nascido e
            criado na roça, pra quem gosta das suas coisas, isso faz uma
            diferença enorme [...]. Para eles pode não valer muita coisa, mas
            pra gente vale muito (Atingida de Paracatu de Baixo, abril de
            2017)”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A casa sintetiza e expressa a própria trajetória da família e
                  é resultado de um percurso longo e intenso de trabalho, lutas
                  e desafios para conseguir construí-la da forma desejada. A
                  casa no território comunitário é o lugar da “morada da vida”
                  (HEREDIA, 1979) e representa liberdade, autonomia, proteção,
                  história, afeto, identidade, memória, lar, família e
                  reciprocidade. No contexto de uma vida provisória e
                  compulsória, o reassentamento é visto, por vezes, como a
                  solução para o fim do sofrimento e das incertezas.
                </p>
                <p style="text-align: center">
                  <strong>Autora: Maryellen Milena de Lima <sup>5</sup></strong>
                </p>
              </template>
            </Card>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img
            width="80%"
            src="../../assets/conteudos_casas_vida_prov_04.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Registro encontrado durante o trabalho de Cartografia Social em uma
            moradia de Paracatu de Baixo. Foto: Acervo Gesta, 2017.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> Desde 2017, venho acompanhando, no âmbito do
              aprendizado, pesquisa e extensão, o processo de reparação dos
              danos aos atingidos e atingidas pelo rompimento da barragem de
              Fundão, em Mariana, junto à equipe de pesquisadores do GESTA/UFMG
              (Grupo de Estudos em Temáticas Ambientais). A maioria dos trechos
              de falas compartilhados ao longo deste texto fazem parte do
              trabalho da
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2020/08/BOLETIM-16-PARACATU-VERSAO-DIGITAL-COMPRIMIDA-12.pdf"
                class="pink--text"
                >Cartografia Social</a
              >
              desenvolvida pelo Gesta junto com 30 famílias da comunidade de
              Paracatu de Baixo. O trabalho consistiu no registro das memórias
              sobre o território afetado através de oficinas e campanhas de
              campo.
            </p>
            <p>
              <sup>2</sup> Jornal A Sirene – Ed. 8 (novembro). Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://issuu.com/jornalasirene/docs/asirene_ed9_novembro_issu"
                target="_blank"
                >https://issuu.com/jornalasirene/docs/asirene_ed9_novembro_issu.</a
              >
            </p>
            <p>
              <sup>3</sup> Jornal A Sirene – Cultura e Memória, disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://jornalasirene.com.br/cultura-memoria/2018/04/03/o-que-e-lazer-para-nos"
                target="_blank"
              >
                https://jornalasirene.com.br/cultura-memoria/2018/04/03/o-que-e-lazer-para-nos.</a
              >
            </p>
            <p>
              <sup>4</sup> No âmbito do acordo extrajudicial denominado
              TAC-Governança, a empresa Ramboll foi contratada para atuar como
              perita do Ministério Público Federal, com o objetivo de realizar o
              monitoramento independente dos Programas de reparação e
              compensação dos danos socioeconômicos e socioambientais
              ocasionados pelo desastre. Com isso, a Ramboll vem produzindo uma
              série de relatórios com o levantamento de questões críticas ao
              longo da Bacia do Rio Doce.
            </p>
            <p>
              <sup>5</sup> Pesquisadora GESTA/UFMG. Mestranda em Sociedade,
              Ambiente e Território (UFMG/Unimontes).
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              HEREDIA, Beatriz Maria Alásia. <strong>A morada da vida</strong>:
              trabalho familiar de pequenos produtores do Nordeste do Brasil.
              Rio de Janeiro: Paz e Terra, 1979. (Série Estudos sobre o
              Nordeste; v. 7).
            </p>
            <p>
              LIMA, Maryellen., Milena.
              <strong>“Aqui não é o nosso lugar”</strong>: Efeitos
              socioambientais, a vida provisória e o processo de (re)construção
              de Paracatu de Baixo, Mariana/MG. 116 f. Monografia apresentada ao
              curso de (Graduação em Ciências Socioambientais) — da Faculdade de
              Filosofia e Ciências Humanas da Universidade Federal de Minas
              Gerais, Minas Gerais, 2018. Disponível em:<a
                class="pink--text"
                style="text-decoration: none;"
                href="https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2019/06/MONOGRAFIA-Maryellen5.pdf"
                target="_blank"
              >
                https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2019/06/MONOGRAFIA-Maryellen5.pdf.</a
              >
            </p>
            <p>
              RAMBOLL. <strong>Relatório de Monitoramento Mensal</strong> – Mês
              052, Abril/2021.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn :to="{ name: 'conteudos_tematicos_casas' }" color="#EDBD43">
            <strong>Voltar para o início de "Casa"</strong></v-btn
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casas /",
          href: "conteudos_tematicos_casas",
        },
        {
          text: "A vida provisória",
          href: "conteudos_tematicos_casas_vida_provisoria",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }

  .border__image2 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image2 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }

  .border__image3 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }

  .border__image3 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }
  .border__image4 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image4 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }

  .border__image5 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image5::before {
    display: none;
  }

  .border__image5 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }
  .border__image6 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image6::before {
    display: none;
  }

  .border__image6 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }

  .border__image7 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image7::before {
    display: none;
  }

  .border__image7 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }

  .border__image8 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image8::before {
    display: none;
  }

  .border__image8 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }

  .border__image9 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image9::before {
    display: none;
  }

  .border__image9 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }

  .border__image10 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image10::before {
    display: none;
  }

  .border__image10 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }

  .border__image11 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image11::before {
    display: none;
  }

  .border__image11 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vp_01.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  padding-right: 5em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vp_02.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vp_03.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 2em 2em 0;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vp_04.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 5em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vp_05.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 4em 2em 0;
}

.border__image6 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image6::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vp_08.png");
  background-size: 100% 100%;
}

.border__image6 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 3em;
}

.border__image7 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image7::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vp_07.png");
  background-size: 100% 100%;
}

.border__image7 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 4em 2em 0;
}

.border__image8 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image8::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vp_08.png");
  background-size: 100% 100%;
}

.border__image8 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 3em;
}

.border__image9 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image9::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vp_07.png");
  background-size: 100% 100%;
}

.border__image9 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 4em 2em 0;
}
</style>
